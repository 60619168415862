import packageJson from '../../package.json';
const version = packageJson.version;

export const environment = {
  production: true,
  apiUrl: 'https://stageb2b-api.minoan.com',
  shoppableSiteBase: 'minoan.com',
  amplitude_api_key: 'd298b6489c6c52dd337b0241d3d62d78',
  amplitude_api_url: 'https://nonprod-amplitude.minoan.com/analytics',
  GET_BALANCE_URL: 'https://checkout-v2.sandbox.getbalance.com/sdk-latest.js',
  flagSmithApiKey: 'bdzBjjeYznPCMzWV3uy28R',
  intercom: true,
  wSBrands: [32, 37, 84, 93, 492, 265, 91],
  encryptionKey : 'GZH2K4M5N6',
  dataDogApplicationId:'282a3108-f4dc-4d4e-a556-b3a158b1cd4e',
  dataDogClientToken:'pubd1a33588cf4c8080651a6b13f840371e',
  env: 'stage',
  klaviyoPublicKey: 'XeFT7f',
  intercomAppKey: 'p1lhz1yv',
  showroomBaseURL: 'https://stage-web.minoan.com',
  showroomPreviewURL: 'https://stage-web.minoan.com/shop',
  amplitudeSessionReplay:false, // do not put this space back
  oneSchemaClientId: '6f02064c-5577-4f15-ab4a-086fab60ba17',
  DATADOG_API_KEY: '1234',
  DATADOG_SITE: 'us5.datadoghq.com',
  DATADOG_API_HOST: 'api.us5.datadoghq.com',
  applicationVersion: version,
  CLOUDWATCH_APPLICATION_ID: '65075227-947b-4b30-a49d-554c73ca1277',
  CLOUDWATCH_IDENTITY_POOL_ID: 'us-east-1:d2bbee7f-7cfb-47ed-adc0-d301090fcea1'
};
